import { ref, reactive, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import useHandleError from '@/utils/useHandleError';

// Localization
import i18n from '@/libs/i18n/index';

export default function useBankCard() {
    const { showErrorMessage } = useHandleError();

    const refBankCardListTable = ref(null);

    const i18nReactive = reactive(i18n);

    const getLocalizedLabels = () => {
        return [
            {
                key: 'card',
                label: i18nReactive.tc('bankcard.card'),
            },
            {
                key: 'is_active',
                label: i18nReactive.tc('bankcard.isActive'),
            },
            {
                key: 'issuer',
                label: i18nReactive.tc('bankcard.issuer'),
            },
            {
                key: 'country',
                label: i18nReactive.tc('bankcard.issuerCountry'),
            },
            {
                key: 'holder_name',
                label: i18nReactive.tc('bankcard.holder'),
            },
            {
                key: 'users',
                label: i18nReactive.tc('bankcard.users'),
            },
        ];
    };

    // Table Handlers
    const tableColumns = ref(getLocalizedLabels());
    const perPage = ref(10);
    const totalBankCards = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const firstChars = ref('');
    const lastChars = ref('');

    const dataMeta = computed(() => {
        const localItemsCount = refBankCardListTable.value
            ? refBankCardListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBankCards.value,
        };
    });

    const refetchBankCardList = () => {
        refBankCardListTable.value.refresh();
    };

    const refetchTableColumnLables = () => {
        tableColumns.value = getLocalizedLabels();
    };

    watch([currentPage, perPage, firstChars, lastChars], () => {
        refetchBankCardList();
    });

    watch(
        () => i18nReactive.locale,
        () => refetchTableColumnLables()
    );

    const fetchBankCardsByMask = (ctx, callback) => {
        if (firstChars.value === '' && lastChars.value === '') {
            callback([]);

            return;
        }

        store
            .dispatch('app-bankcard/fetchBankCardsByMask', {
                locale: i18nReactive.locale,
                params: {
                    per_page: perPage.value,
                    page: currentPage.value,
                    first_chars: firstChars.value,
                    last_chars: lastChars.value,
                },
            })
            .then((response) => {
                const { items = [], total = 0 } = response.data;

                callback(items);
                totalBankCards.value = total;
            })
            .catch(showErrorMessage);
    };

    return {
        tableColumns,
        perPage,
        currentPage,
        totalBankCards,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refBankCardListTable,
        firstChars,
        lastChars,

        fetchBankCardsByMask,
        refetchBankCardList,
    };
}
