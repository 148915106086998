<template>
    <validation-observer ref="bankCardMaskForm" #default="{ invalid }" slim>
        <b-form inline @submit.prevent>
            <b-form-group label-for="first-chars" :label="$t('bankcard.mask')">
                <validation-provider
                    #default="{ errors }"
                    name="first-chars"
                    rules="numeric|max:6"
                >
                    <b-form-input
                        id="first-chars"
                        v-model="form.firstChars"
                        class="mb-2 mb-sm-0 mx-sm-50 custom-size--medium"
                        :class="{ 'border border-danger': errors[0] }"
                        placeholder="123456"
                        maxlength="6"
                    />
                </validation-provider>
            </b-form-group>

            <b-form-group label-for="last-chars" label="****">
                <validation-provider
                    #default="{ errors }"
                    name="last-chars"
                    rules="numeric|max:4|required"
                >
                    <b-form-input
                        id="last-chars"
                        v-model="form.lastChars"
                        class="mb-2 mb-sm-0 ml-sm-50 mr-sm-3 custom-size--small"
                        :class="{ 'border border-danger': errors[0] }"
                        placeholder="4321"
                        maxlength="4"
                        required
                    />
                </validation-provider>
            </b-form-group>

            <b-button
                type="reset"
                variant="outline-secondary"
                class="mb-2 mb-sm-0 mr-sm-1"
                @click="resetSearchFrom"
            >
                {{ $t('ui.reset') }}
            </b-button>

            <b-button
                type="submit"
                variant="primary"
                :disabled="invalid"
                @click="updateSearchForm"
            >
                {{ $t('ui.search') }}
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { required, numeric, max } from '@validations';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,

        BForm,
        BFormGroup,
        BFormInput,
        BButton,
    },

    props: {
        firstChars: {
            type: String,
            required: true,
        },

        lastChars: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            form: {
                firstChars: this.firstChars,
                lastChars: this.lastChars,
            },

            // Validation rules
            required,
            numeric,
            max,
        };
    },

    methods: {
        emitForm() {
            this.$emit('update:firstChars', this.form.firstChars);
            this.$emit('update:lastChars', this.form.lastChars);
        },
        updateSearchForm() {
            this.$refs.bankCardMaskForm.validate().then(() => {
                this.emitForm();
            });
        },

        resetSearchFrom() {
            this.form.firstChars = '';
            this.form.lastChars = '';
            this.emitForm();
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-size--medium {
    max-width: 90px;
}
.custom-size--small {
    max-width: 70px;
}
</style>
