<template>
    <div class="users-list">
        <!-- Table Container Card -->
        <b-card no-body class="users-card mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>
                            {{ $t('ui.perPage') }}
                        </label>

                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />

                        <label>
                            {{ $t('ui.entries') }}
                        </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="d-flex justify-content-end">
                        <bank-card-form
                            :first-chars.sync="firstChars"
                            :last-chars.sync="lastChars"
                        />
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refBankCardListTable"
                class="users-table position-relative"
                :items="fetchBankCardsByMask"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('ui.searchNotFound')"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Card -->
                <template #cell(card)="data">
                    <bank-card-mask
                        :type="data.item.type"
                        :mask="
                            data.item.first_chars +
                            ' **** ' +
                            data.item.last_chars
                        "
                    />
                </template>

                <!-- Column: Is active -->
                <template #cell(is_active)="data">
                    <div class="text-nowrap">
                        <feather-icon
                            v-if="data.item.is_active"
                            icon="CheckIcon"
                            size="18"
                            class="text-success"
                        />

                        <feather-icon
                            v-else
                            icon="XIcon"
                            size="18"
                            class="text-danger"
                        />
                    </div>
                </template>

                <!-- Column: Issuer Country -->
                <template #cell(country)="data">
                    <span>
                        {{ data.item.country.name }}
                    </span>
                </template>

                <!-- Column: Users -->
                <template #cell(users)="data">
                    <div
                        v-for="user in data.item.users"
                        :key="user.email"
                        class="custom-delimiter"
                    >
                        <b-link
                            :to="{
                                name: 'users-view',
                                params: { id: user.id },
                            }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ user.email }}
                        </b-link>
                    </div>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <i18n class="text-muted" tag="span" path="ui.showing">
                            <template v-slot:from>
                                {{ dataMeta.from }}
                            </template>

                            <template v-slot:to>
                                {{ dataMeta.to }}
                            </template>

                            <template v-slot:of>
                                {{ dataMeta.of }}
                            </template>
                        </i18n>
                    </b-col>

                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBankCards"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink, BPagination } from 'bootstrap-vue';
import vSelect from 'vue-select';
import usePositionedToast from '@/utils/usePositionedToast';
import BankCardMask from '@/views/components/bankcard/BankCardMask.vue';
import useBankCard from '../useBankCard';
import BankCardForm from './BankCardForm.vue';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BLink,
        BPagination,

        vSelect,

        BankCardForm,
        BankCardMask,
    },

    created() {
        this.debouncedUpdateSearchQuery = this.lodash.debounce((query) => {
            this.updateSearchQuery(query);
        }, 1000);
    },

    methods: {
        updateSearchQuery(value) {
            this.searchQuery = value;
        },
    },

    setup() {
        const { showNotification } = usePositionedToast();

        const {
            fetchBankCardsByMask,
            tableColumns,
            perPage,
            currentPage,
            totalBankCards,
            dataMeta,
            perPageOptions,
            firstChars,
            lastChars,
            sortBy,
            isSortDirDesc,
            refBankCardListTable,
            refetchBankCardList,

            // UI
            resolveUserSubscriptionVariant,
        } = useBankCard();

        return {
            fetchBankCardsByMask,
            tableColumns,
            perPage,
            currentPage,
            totalBankCards,
            dataMeta,
            perPageOptions,
            firstChars,
            lastChars,
            sortBy,
            isSortDirDesc,
            refBankCardListTable,
            refetchBankCardList,

            // UI
            resolveUserSubscriptionVariant,

            showNotification,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.users-table {
    height: 100% !important;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.per-page-selector {
    width: 90px;
}

.custom-delimiter {
    margin-top: 1rem;
}

.custom-delimiter:first-of-type {
    margin-top: 0;
}
</style>
