<template>
    <div class="d-flex align-items-center">
        <b-img
            class="mr-50"
            width="32"
            height="32"
            fluid
            :src="getCreditCardLogoName(type)"
            :alt="`${type} logo`"
        />

        <component :is="isBold ? 'h6' : 'span'" class="m-0">
            {{ cardNumber }}
        </component>
    </div>
</template>

<script>
import { BImg } from 'bootstrap-vue';
import { getCreditCardLogoName } from '@/utils/formatter';

export default {
    components: {
        BImg,
    },

    props: {
        /**
         * Card's Payment system, like 'visa', 'mastercard'
         * passes for image logo
         */
        type: {
            type: String,
            required: true,
        },

        mask: {
            type: String,
            required: true,
        },

        isBold: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        cardNumber() {
            try {
                const mask = `${this.mask.slice(0, 4)} ${this.mask.slice(4)}`;

                return mask.replace(' **** ', '** **** ');
            } catch (_) {
                return this.mask;
            }
        },
    },

    setup() {
        return {
            // External Methods
            getCreditCardLogoName,
        };
    },
};
</script>
